.frameChild {
  width: 1440px;
  height: 900px;
  position: relative;
  background-color: var(--color-black);
  display: none;
  max-width: 100%;
  z-index: 0;
}
.pexelsPhotoByYogendraSing {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  z-index: 1;
}
.corpolympics {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  z-index: 2;
}
.corpolympicsWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.main,
.rectangleParent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.rectangleParent {
  align-self: stretch;
  background-color: var(--color-black);
  align-items: center;
  height: 800px;
  padding: 30px; 
  box-sizing: border-box;
  gap: var(--gap-176xl);
  max-width: 100%;
  text-align: left;
  font-size: 32px;
  color: var(--color-whitesmoke);
  font-family: var(--font-montserrat);
}
.main {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  overflow: hidden;
  align-items: flex-start;
  gap: var(--gap-71xl);
  letter-spacing: normal;
}
@media screen and (max-width: 1130px) {
  .corpolympics {
    font-size: 26px;
  }
  .rectangleParent {
    padding-top: var(--padding-7xl);
    padding-bottom: 188px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .main {
    gap: var(--gap-71xl);
  }
}
@media screen and (max-width: 550px) {
  .corpolympics {
    font-size: 19px;
  }
  .rectangleParent {
    gap: var(--gap-176xl);
    padding-top: var(--padding-xl);
    padding-bottom: 122px;
    box-sizing: border-box;
  }
  .main {
    gap: var(--gap-71xl);
  }
}
