@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-nothing-you-could-do: "Nothing You Could Do";

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-lg: 18px;
  --font-size-17xl: 36px;
  --font-size-3xl: 22px;
  --font-size-10xl: 29px;
  --font-size-53xl: 72px;
  --font-size-24xl: 42px;
  --font-size-39xl: 58px;
  --font-size-40xl: 54px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #fafafa;
  --color-gray-200: #0e1314;
  --color-black: #000;
  --color-gainsboro: #d9d9d9;
  --color-whitesmoke: #f2f2f2;
  --color-blue: #48cae4;
  --color-blue-gainsboro: #42b3c9;

  /* Gaps */
  --gap-71xl: 90px;
  --gap-xl: 20px;
  --gap-smi: 5px;
  --gap-3xs: 10px;
  --gap-41xl: 60px;
  --gap-21xl: 40px;
  --gap-27xl: 46px;
  --gap-6xl: 25px;
  --gap-18xl: 37px;
  --gap-176xl: 195px;
  --gap-11xl: 30px;

  /* Paddings */
  --padding-234xl: 253px;
  --padding-61xl: 80px;
  --padding-48xl: 67px;
  --padding-57xl: 76px;
  --padding-145xl: 164px;
  --padding-25xl: 44px;
  --padding-19xl: 38px;
  --padding-21xl: 40px;
  --padding-53xl: 72px;
  --padding-28xl: 47px;
  --padding-mid: 17px;
  --padding-69xl: 88px;
  --padding-xl: 20px;
  --padding-38xl: 57px;
  --padding-lg: 18px;
  --padding-3xl: 22px;
  --padding-47xl: 66px;
  --padding-7xl: 26px;
  --padding-24xl: 43px;
  --padding-8xs: 5px;
  --padding-11xs: 2px;
  --padding-mini: 15px;
  --padding-sm: 14px;
  --padding-13xl: 32px;
  --padding-2xl: 21px;

  /* Border radiuses */
  --br-base: 16px;
  --br-21xl: 40px;
}
