.footerChild {
  height: 400px;
  width: 1440px;
  position: relative;
  background-color: var(--color-gray-200);
  display: none;
  max-width: 100%;
}
.copyright2024,
.lyonFrance {
  position: relative;
  z-index: 1;
}
.corpOlympicsCopyright {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.facebookIcon,
.linkedinIcon,
.linkedinIcon1,
.twitterPhotoroom1Icon {
  height: 40px;
  width: 40px;
  position: relative;
  object-fit: cover;
  min-height: 50px;
}

.linkedinIcon1 {
  object-fit: cover;
}
.socials {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  z-index: 1s;
}
.contactcorpolympicscom {
  position: relative;
  white-space: nowrap;
  z-index: 1;
}
.footer,
.socialFrames {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.footer {
  align-self: stretch;
  background-color: var(--color-gray-200);
  flex-direction: row;
  justify-content: space-between;
  padding: var(--padding-234xl) var(--padding-61xl) var(--padding-48xl)
    var(--padding-57xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-montserrat);
}
@media screen and (max-width: 750px) {
  .footer {
    flex-wrap: wrap;
    padding-left: var(--padding-19xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .footer {
    padding-top: var(--padding-145xl);
    padding-bottom: var(--padding-25xl);
    box-sizing: border-box;
  }
}
