.leSportEn,
.rvolutionner {
  margin: 0;
}
.rvolutionnerLeSportContainer {
  margin: 0;
  height: 176px;
  position: relative;
  font-size: inherit;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  z-index: 2;
}
.contribuerAuBien,
.unSolutionSimple {
  margin: 0;
}
.unSolutionSimpleContainer {
  position: relative;
  font-size: var(--font-size-lg);
  white-space: pre-wrap;
  display: inline-block;
  max-width: 100%;
  z-index: 2;
}
.aidezNousDansNotre {
  position: relative;
  font-size: var(--font-size-lg);
  font-family: var(--font-montserrat);
  color: var(--color-black);
  text-align: center;
}
.cta {
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: var(--padding-lg) var(--padding-xl) var(--padding-mid)
    var(--padding-2xl);
  background-color: var(--color-white);
  border-radius: var(--br-21xl);
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.25);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
  white-space: nowrap;
  z-index: 2;
}
.cta:hover {
  background-color: var(--color-gainsboro);

}
.fluentarrowReply32FilledIcon {
  width: 68.9px;
  height: 68.9px;
  position: relative;
  margin: 0 !important;
  right: 246.1px;
  bottom: -34.9px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 2;
}
.rvolutionnerLeSportEnEntrParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-11xl);
  max-width: 100%;
}
.frameWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-53xl);
  color: var(--color-whitesmoke);
  font-family: var(--font-montserrat);
}
@media screen and (max-width: 1130px) {
  .rvolutionnerLeSportContainer {
    font-size: var(--font-size-39xl);
  }
}
@media screen and (max-width: 933.6px) {
  .rvolutionnerLeSportContainer {
    font-size: var(--font-size-40xl);
  }
  .aidezNousDansNotre{
    font-size: 18px;
  }
}
@media screen and (max-width: 550px) {
  .rvolutionnerLeSportContainer {
    font-size: var(--font-size-24xl);
  }
}
@media screen and (max-width: 420px) {
  .aidezNousDansNotre{
    font-size: 16px;
  }
}
