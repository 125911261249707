.cestQuoi,
.enQuelquesMots {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-family: inherit;
}
.enQuelquesMots {
  height: 88px;
  font-weight: 700;
  display: inline-block;
  max-width: 100%;
}
.cestQuoi {
  font-weight: 500;
}
.cestQuoiWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-13xl) 0 var(--padding-2xl);
}
.avatarTennisPlayer1 {
  height: 166px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.avatarFrame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 var(--padding-11xs);
}
.challengeFrame,
.footballPlayerAvatar {
  width: 102px;
  height: 25px;
  position: absolute;
  margin: 0 !important;
  bottom: 131px;
  left: 22.64px;
  background-color: var(--color-gainsboro);
  transform: rotate(1deg);
  transform-origin: 0 0;
}
.challengeFrame {
  width: 105.1px;
  bottom: 84.57px;
  left: 23px;
  transform: rotate(-1deg);
}
.uneApplicationPermettant {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  z-index: 1;
}
.plate1 {
  width: 305px;
  border-radius: var(--br-base);
  background-color: var(--color-gray-100);
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mid) var(--padding-mini) var(--padding-47xl)
    var(--padding-7xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-18xl);
  transition: transform 0.5s;
  transform-style: preserve-3d;
}
.plate1:hover {
  transform: rotate(1deg) scale(1.05);
}
.advantageRectangle,
.advantageRectangle1 {
  position: absolute;
  top: 0;
  left: 177px;
  background-color: var(--color-gainsboro);
  width: 80px;
  height: 25px;
  transform: rotate(1deg);
  transform-origin: 0 0;
}
.advantageRectangle1 {
  top: 22px;
  left: 2px;
  width: 146px;
  z-index: 1;
}
.advantageRectangleParent {
  width: 187px;
  height: 49px;
  position: absolute;
  margin: 0 !important;
  bottom: 85px;
  left: 21px;
}
.plate3Child {
  width: 110px;
  height: 24.6px;
  position: absolute;
  margin: 0 !important;
  right: 25px;
  bottom: 131.3px;
  background-color: var(--color-gainsboro);
  transform: rotate(-1deg);
  transform-origin: 0 0;
}
.principe {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 500;
  font-family: inherit;
}
.avatarWomanRunning1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.principleFrame,
.principleFrameWrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.principleFrame {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-6xl);
}
.principleFrameWrapper {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-8xs) 0 var(--padding-11xs);
}
.dfiezVosCollgues,
.plate3 {
  align-self: stretch;
  position: relative;
}
.dfiezVosCollgues {
  font-size: var(--font-size-lg);
  z-index: 2;
}
.plate3 {
  width: 305px;
  border-radius: var(--br-base);
  background-color: var(--color-gray-100);
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-3xl) var(--padding-47xl)
    var(--padding-7xl);
  box-sizing: border-box;
  gap: var(--gap-21xl);
  transition: transform 0.5s;
  transform-style: preserve-3d;
}
.plate3:hover {
  transform: rotate(1deg) scale(1.05);
}
.avantages {
  margin: 0;
  height: 44px;
  position: relative;
  font-size: inherit;
  font-weight: 500;
  font-family: inherit;
  display: inline-block;
}
.plate2Child {
  width: 126px;
  height: 25px;
  position: absolute;
  margin: 0 !important;
  right: 41px;
  bottom: 176px;
  background-color: var(--color-gainsboro);
  transform: rotate(1deg);
  transform-origin: 0 0;
}
.avatarFootballPlayer1 {
  width: 167px;
  flex: 1;
  position: relative;
  max-height: 100%;
  object-fit: cover;
}
.amliorerLaQualit {
  width: 253px;
  position: relative;
  font-size: var(--font-size-lg);
  display: inline-block;
  z-index: 1;
}
.plate2 {
  align-self: stretch;
  width: 305px;
  border-radius: var(--br-base);
  background-color: var(--color-gray-100);
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mid) var(--padding-mid) var(--padding-69xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-27xl);
  transition: transform 0.5s;
  transform-style: preserve-3d;
}
.plate2:hover {
  transform: rotate(1deg) scale(1.05);
}
.plate1Parent {
  max-height: 100%;
  margin-top: 20px;
  flex-shrink: 100;
  justify-content: center;
  gap: var(--gap-21xl);
  align-self: stretch;
  display: flex;
  flex-direction: row;
}
.plateFrameAInner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-17xl);
}
.plateFrameA,
.plateFrameAWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
} 
 .plateFrameA {
  flex: 1;
  width: 100%;
  flex-direction: column;
  gap: var(--gap-41xl);
} 
.plateFrameAWrapper {
  width: 100%;
  flex-direction: column;
  padding: 0 var(--padding-61xl) var(--padding-53xl);
  box-sizing: border-box;
  text-align: left;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-53xl);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}
@media screen and (max-width: 1200px) {
  .plateFrameA {
    gap: var(--gap-41xl);
  }
  .plateFrameAWrapper {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .enQuelquesMots {
    font-size: var(--font-size-39xl);
  }
  .avantages,
  .cestQuoi,
  .principe {
    font-size: var(--font-size-10xl);
  }
}
@media screen and (max-width: 1000px) {
  .plate1Parent {
    flex-direction: column;
  }
}
@media screen and (max-width: 750px) {
  .plate1Parent {
    gap: var(--gap-21xl);
  }
  .plateFrameAWrapper {
    padding-bottom: var(--padding-28xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 475px) {
    .enQuelquesMots {
    font-size: var(--font-size-24xl);
  } 
}

